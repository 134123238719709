<template lang="pug">
  .setting-item-expanded
    .general-data
      .checkboxes
        CheckboxesFilter(
          :value="setting.days_options"
          :is-disabled="isDisabledCheckboxes"
          :is-invalid="isSelectedDayInvalid"
          :tooltip-title="$t('inventory_management.inventory_for_future.warning.has_intersections')"
          :is-tooltip-shown="isSelectedDayInvalid"
          :inactive="inactiveState.inactiveReference"
          @input="handleChangeDaysOptions"
        )
        .checkboxes-message
          | {{ $t("inventory_management.inventory_long_period.weekday_filter.notification_message") }}
      .dropdowns
        .reference.m-b-10
          AppTooltip.inactive-red-icon(
            v-if="inactiveState.inactiveReference && !isOrganizationWithSharedInventory"
            icon="exclamation-triangle"
            :title="$t('inventory_management.inventory_for_future.warning.shop_inactive')"
          )
          AppTooltip.inactive-red-icon(
            v-else-if="inactiveState.inactiveAllShops && isOrganizationWithSharedInventory"
            icon="exclamation-triangle"
            :title="$t('inventory_management.inventory_for_future.warning.group_and_all_shops_inactive')"
          )
          //- TODO: add translation ^^
          .title {{ $t(referenceTranslations.referenceTitle) }}
          .reference-select
            AppDropdown(
              close-on-select
              :value="setting.reference"
              :items="referencesItems"
              :placeholder="$t(referenceTranslations.referencePlaceholder)"
              :loading="referencesLoading"
              :is-item-disabled="isItemDisabled"
              @select="handleChangeReference"
              order-direction="keep"
            )
        .car-classes
          .title {{ $t("inventory_management.inventory_for_future.car_classes") }}
          .car-class-select
            AppDropdown(
              v-if="isEmpty(setting.reference)"
              :placeholder="$t('inventory_management.select_car_classes')"
              :disabled="true"
              v-b-tooltip.hover="{ placement: 'left' }"
              :title="$t(referenceTranslations.carClassDropdownTip)"
            )
            AppDropdown(
              v-else
              checkbox
              batch-select
              multiple
              allow-empty
              close-on-select
              searchable
              :loading="carClassesLoading"
              value-key="id"
              title-key="name"
              :placeholder="$t('inventory_management.select_car_classes')"
              :value="setting.car_classes"
              :items="carClassesItems"
              order-direction="preorder"
              :is-item-disabled="isItemDisabled"
              @select="handleChangeCarClasses"
            )
    .inventory-data(
      :id="setting.reference.id"
    )
      SettingList.settings-list(
        :car-classes="setting.car_classes"
        :intersections="intersections"
        :is-shared-inventory-disabled="isSharedInventoryDisabled"
        :settings-object="setting.inventory"
        :inactive="inactiveState.inactiveReference"
        :inventory-fields="inventoryFields"
        @change-settings="handleChangeSetting('inventory', $event)"
        @remove-car-class="handleRemoveCarClass"
      )
    .actions
      .active
        AppToggle(
          :value="setting.active"
          :color="{ checked: '#2e9fad' }"
          :disabled="isActiveToggleDisabled"
          @change="handleChangeSetting('active', $event.value)"
        )
        span.m-l-10(
          :class="{ inactive: !setting.active }"
        ) {{ $t("inventory_management.inventory_for_future.active") }}
      .d-flex.align-items-center.buttons-container
        span.confirmation-tip(
          :class="tipClasses"
        ) {{ $t("inventory_management.inventory_for_future.confirm_message") }}
        AppIconButton.remove-component(
          :class="removeClasses"
          icon="trash-alt"
          :title="removeTitle"
          @click="removeHandler"
        )
        AppIconButton.cancel-component(
          title="inventory_management.inventory_for_future.cancel"
          :icon="isDeletingActive ? 'undo-alt' : 'times'"
          :class="cancelClasses"
          :title="cancelTitle"
          @click="cancelHandler"
        )
        AppIconButton.save-component(
          title="inventory_management.inventory_for_future.save_and_collapse"
          :disabled="hasIntersections"
          :iconBefore="false"
          icon="chevron-up"
          @click="$emit('switch-component', $event)"
          :class="{ 'save-component_hide': isDeletingActive }"
        )
</template>

<script>
  // misc
  import { isEmpty, values, some, isNil, flatten } from "lodash-es"
  import withLocale from "@/mixins/withLocale"
  import { isSharedInventoryEnabled as isOrganizationWithSharedInventory } from "@/helpers/organization"

  export default {
    components: {
      CheckboxesFilter: () => import("@/components/InventoryManagement/CheckboxesFilter"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      SettingList: () => import("@/components/InventoryManagement/SetForFutureDays/SettingList"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      SavingStatusNotice: () => import("./SavingStatusNotice"),
      AppConfirmationRemove: () => import("@/components/elements/AppConfirmationRemove"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      setting: Object,
      referencesItems: Array,
      referencesLoading: Boolean,
      carClassesItems: Array,
      carClassesLoading: Boolean,
      selectedDaysOptions: {
        type: Array,
        default: () => []
      },
      isUnsavedChanges: Boolean,
      isNewSetting: Boolean,
      isInvalidFilters: Boolean,
      isNotSelectedDaysOptions: Boolean,
      inactiveState: {
        type: Object,
        default: () => ({
          inactiveAll: false,
          inactiveReference: false,
          inactiveAllCarClasses: false,
          inactiveSomeCarClasses: false
        })
      },
      intersections: {
        type: Object,
        default: () => {}
      },
      inventoryFields: Array
    },

    data() {
      return {
        isDeletingActive: false
      }
    },

    mixins: [withLocale],

    computed: {
      isOrganizationWithSharedInventory,

      // is using for regular Inventory management for cases when selected shop
      // is included to some inventory group
      isSharedInventoryDisabled({ setting }) {
        return isEmpty(setting.reference.inventory_group) && !isOrganizationWithSharedInventory()
      },

      intersectedDays() {
        return flatten(values(this.intersections))
      },

      hasIntersections() {
        return !isEmpty(this.intersectedDays)
      },

      isActiveToggleDisabled() {
        return (
          this.isInvalidFilters ||
          this.isNotSelectedDaysOptions ||
          this.isInventoryInvalid ||
          this.inactiveState.inactiveReference ||
          this.inactiveState.inactiveAllCarClasses ||
          this.isOrganizationInactiveAndShared()
        )
      },

      inventoryValues() {
        return values(this.setting.inventory)
      },

      isInventoryInvalid() {
        return some(this.inventoryValues, value => this.isInventoryValueInvalid(value))
      },

      referenceTranslations() {
        const shared = isOrganizationWithSharedInventory()

        return {
          referenceTitle: `inventory_management.inventory_for_future.${shared ? "inventory_group" : "shop"}`,
          referencePlaceholder: `inventory_management.${shared ? "select_inventory_group" : "select_shop"}`,
          carClassDropdownTip: `inventory_management.${shared ? "select_inventory_group_first" : "select_shop_first"}`
        }
      },

      tipClasses() {
        return {
          "confirmation-tip_active": this.isDeletingActive,
          [`confirmation-tip_active-${this.currentLocale}`]: this.isDeletingActive
        }
      },

      removeClasses() {
        return { [`remove-component_active-${this.currentLocale}`]: this.isDeletingActive }
      },

      removeTitle() {
        return this.isDeletingActive
          ? "inventory_management.inventory_for_future.delete"
          : "inventory_management.inventory_for_future.delete_setting"
      },

      cancelClasses() {
        return { [`cancel-component_active-${this.currentLocale}`]: this.isDeletingActive }
      },

      cancelTitle() {
        return this.isDeletingActive
          ? "inventory_management.inventory_for_future.back_to_edit"
          : "inventory_management.inventory_for_future.cancel"
      }
    },

    methods: {
      isEmpty,

      isInventoryValueInvalid({ sale_stop, shared_inventory_limit, shop_inventory_limit }) {
        if (this.isOrganizationWithSharedInventory) {
          return !sale_stop && isNil(shared_inventory_limit)
        } else {
          return !sale_stop && isNil(shop_inventory_limit)
        }
      },

      isItemDisabled({ sync, selectable }) {
        return sync === false || selectable === false
      },

      handleChangeDaysOptions(daysOptions) {
        this.$emit("change-days-options", daysOptions)
      },

      handleChangeReference(reference) {
        this.$emit("select-reference", reference)
      },

      handleChangeCarClasses(carClasses) {
        this.$emit("select-car-classes", carClasses)
      },

      handleChangeSetting(key, value) {
        this.$emit("change-setting", { ...this.setting, [key]: value })
      },

      isSelectedDayInvalid(option) {
        return this.intersectedDays.includes(option)
      },

      isDisabledCheckboxes(option) {
        return (
          this.isInvalidFilters || this.selectedDaysOptions.includes(option) || this.isOrganizationInactiveAndShared()
        )
      },

      handleRemoveCarClass({ id }) {
        const carClasses = this.setting.car_classes.filter(c => c.id !== id)
        this.$emit("select-car-classes", carClasses)
      },

      isOrganizationInactiveAndShared() {
        return isOrganizationWithSharedInventory && this.inactiveState.inactiveAllShops
      },

      handleDeleteSetting(isDeletingActive) {
        this.isDeletingActive = isDeletingActive
      },

      removeHandler() {
        this.isDeletingActive ? this.$emit("delete-setting") : this.handleDeleteSetting(true)
      },

      cancelHandler(event) {
        this.isDeletingActive ? this.handleDeleteSetting(false) : this.$emit("cancel-component", event)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/auto-set-inventory-for-future.sass"
  @import "@/assets/styles/mixins/common.sass"

  .setting-item-expanded
    font-size: 0.8rem

    .inactive-red-icon
      margin-right: 5px
      ::v-deep
        svg
          color: $default-red


    .general-data
      align-items: flex-start
      display: flex
      margin-bottom: 15px

      .checkboxes
        width: 50%

        &-message
          color: $default-gray-medium
          font-size: 0.7rem
          text-align: center

      .dropdowns
        width: 50%

        .reference,
        .car-classes
          align-items: center
          display: flex
          float: right
          height: 38px

          .title
            font-weight: 500
            margin-right: 15px

        .reference-select,
        .car-class-select
          width: 290px

    .inventory-data
      max-height: 300px

    .actions
      align-items: center
      display: flex
      justify-content: space-between
      margin-top: 15px

      .active
        align-items: center
        display: flex
        span
          color: $toggle-color

          &.inactive
            color: $default-gray

      .buttons-container

        position: relative

        ::v-deep
          svg
            path
              fill: $default-white
          .title
            color: $default-white

      .confirmation-tip
        opacity: 0
        position: absolute
        transition: all .3s ease-in-out
        color: $default-red
        pointer-events: none
        text-wrap: nowrap

        &_active
          opacity: 1
          &-en
            transform: translateX(-40px)
          &-ja
            transform: translateX(-15px)


      .remove-component
        background-color: $default-red
        border-color: $default-red
        transition: all .5s ease-in-out
        margin-right: 15px

        ::v-deep
          .fade
            &-enter-active,
            &-leave-active
              transition: all .5s ease-in-out

        &_active
          &-en
            transform: translateX(314px)
          &-ja
            transform: translateX(275px)

      .save-component
        font-weight: 700
        background-color: $default-purple

        ::v-deep
          .title
            background-color: $default-purple
            color: $default-white
            margin-right: 5px

          svg
            color: $default-white

      .save-component
        transition: opacity .5s ease-in-out

        &_hide
          opacity: 0
          pointer-events: none

      .cancel-component
        margin-right: 15px

        &_active
          &-en
            transform: translateX(90px)
          &-ja
            transform: translateX(45px)

        ::v-deep
          svg
            path
              fill: $default-purple
          .title
            color: $default-purple
</style>
